<template>

  <div class="row" ref="permissionForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <ValidationProvider
                    vid="name"
                    rules="required"
                    name="The title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Title"
                              name="title"
                              fou
                              v-model="formData.title">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <div class="row" v-for="(slug,slugIndex) in formData.slugs">
                    <div class="col-sm-9">
                      <fg-input type="text"
                                :key="slugIndex"
                                :label="'Permission Url '+(slugIndex+1)"
                                v-model="slug.permission_slug">
                      </fg-input>
                    </div>
                    <div v-if="slugIndex > 0" class="col-sm-3 align-self-center mt-3">
                      <l-button
                        @click="removeSlug(slugIndex)"
                        type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
                      </l-button>
                    </div>
                  </div>
                  <l-button
                    type="success"
                    @click="addNewSlug()"
                    size="sm">
                       <span class="btn-label">
                         <i class="fa fa-plus"></i>
                         </span>
                  </l-button>
                </div>
              </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/permissions/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";

extend("required", {
  message: "{_field_} is required"
});

export default {
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        title: "",
        slugs: [
          {"permission_slug": ""}
        ]
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.permissionForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Permissions";
      this.getPermission();
    } else {
      this.editMode = false;
      this.formTitle = "Add Permission";
      this.loader.hide();
    }
  },


  methods: {

    async getPermission() {
      try {
        let response = await this.axios.get("permissions/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Permission Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("permissions/update/" + this.id, this.formData);
        successMessage = "Permission Updated Successfully";
      } else {
        request = this.axios.post("permissions/create", this.formData);
        successMessage = "Permission Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/permissions/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    addNewSlug() {
      this.formData.slugs.push({"permission_slug": ""});
    },

    removeSlug(index) {
      this.formData.slugs.splice(index, 1);
    },

  }
}
</script>

<style>
</style>
